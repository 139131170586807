import * as React from "react"
import Contact from "../components/contact/contact"
import Hero from "../components/hero/hero"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Services = () => (
  <Layout>
    <Seo title="Services" />

    <Hero />

    <Contact />

  </Layout>
)

export default Services
